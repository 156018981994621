export function getCurrentFieldGroupId<T extends boolean = true>(
  shouldThrow?: T,
): T extends false ? number | undefined : number
export function getCurrentFieldGroupId(shouldThrow = true) {
  const route = useRoute('division-companyId-manager-referenceName-id')
  const fieldGroupId = route.params.id as string | undefined

  if (shouldThrow && !fieldGroupId) {
    throw new Error('Field group not available')
  }

  return fieldGroupId ? parseInt(fieldGroupId) : undefined
}
